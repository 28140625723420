import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import layoutStyle from '../styles/layout';
import { colors, media } from '../styles/constants';
import ChevronLeft from '../assets/svg/chevron-left.svg';
import ChevronRight from '../assets/svg/chevron-right.svg';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ApplicationsContainer = styled.div`
  h2 {
    padding-bottom: 3rem;
    text-align: center;
    font-size: 2rem;
  }

  ${layoutStyle}

  > div {
    position: relative;
  }

  .carousel {
    box-shadow: 0 1px 9px rgba(0, 0, 0, 0.35);
    border: 1px solid ${colors.grey};
    border-radius: 0.25rem;
    position: relative;
  }

  button {
    display: none;
    visibility: hidden;

    @media (min-width: ${media.tabletPortrait}px) {
      display: flex;
      visibility: visible;
      border: none;
      background-color: transparent;
      transform: translateY(-50%);
      top: 50%;
      position: absolute;

      &:first-of-type {
        left: -3rem;
      }

      &:last-of-type {
        right: -3rem;
      }
    }
  }
`;

const CarouselElement = styled.div`
  display: flex;
  background-color: ${colors.background};
  align-items: center;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
  }

  > div:first-child {
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      flex-direction: row;
      height: 100%;
      width: 50%;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      min-height: 20rem;
      max-height: 20rem;

      @media (min-width: ${media.tabletPortrait}px) {
        max-height: none;
      }
    }
  }

  > div:last-child {
    padding: 2rem;
    text-align: left;
    display: flex;
    flex-direction: column;

    @media (min-width: ${media.tabletPortrait}px) {
      width: 50%;
      justify-content: center;
    }

    h3 {
      font-size: 1.5rem;
      padding-bottom: 1.75rem;
      text-align: center;

      @media (min-width: ${media.tabletPortrait}px) {
        text-align: left;
      }
    }

    ul {
      list-style: disc;
      padding-left: 1.25rem;

      li {
        padding-bottom: 1rem;
        line-height: 1.25;
      }
    }
  }
`;

const Dots = styled.ul`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  list-style: none;

  li {
    transition: opacity 150ms ease-in-out;
    opacity: 0.3;
    background-color: ${colors.blue};
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    margin: 0 0.5rem;
  }

  ${(props) =>
    props.selected &&
    css`
      li:nth-child(${props.selectedSlide}) {
        opacity: 1;
      }
    `}
`;

function Applications() {
  const images = useStaticQuery(graphql`
    query {
      containers: file(relativePath: { eq: "containers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      wasteManagement: file(relativePath: { eq: "waste-management.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      workflow: file(relativePath: { eq: "workflow.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pedestrian: file(relativePath: { eq: "pedestrian.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tuggertrain: file(relativePath: { eq: "tugger_train.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      assetmanagement: file(relativePath: { eq: "asset_management.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [selectedSlide, setSelected] = useState(0);

  return (
    <ApplicationsContainer>
      <h2>Use Cases</h2>
      <div>
        <button
          onClick={() => {
            setSelected(selectedSlide - 1);
          }}
        >
          <ChevronLeft />
        </button>
        <button
          onClick={() => {
            setSelected(selectedSlide + 1);
          }}
        >
          <ChevronRight />
        </button>
        <div>
          <Carousel
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            // autoPlay
            emulateTouch
            infiniteLoop
            selectedItem={selectedSlide}
            onChange={(item) => setSelected(item)}
            interval={5000}
            stopOnHover
            transitionTime={500}
          >
            <CarouselElement>
              <div>
                <Img fluid={images.containers.childImageSharp.fluid} />
              </div>
              <div>
                <h3>Returnable Container Inventory</h3>
                <ul>
                  <li>
                    Keep track of your returnable containers inventory, such as
                    beer kegs, gas bottles, pallets, etc.
                  </li>
                  <li>
                    Measure average round-trip-times, delivery times and
                    automate labor intensive picking tasks
                  </li>
                  <li>
                    Asset visibility in industrial environments due to
                    outstanding reading distance even on metal containers
                  </li>
                </ul>
              </div>
            </CarouselElement>
            <CarouselElement>
              <div>
                <Img fluid={images.wasteManagement.childImageSharp.fluid} />
              </div>
              <div>
                <h3>Smart Waste Management</h3>
                <ul>
                  <li>
                    Map and position all waste containers spread throughout the
                    city
                  </li>
                  <li>
                    Real-time and reliable information of containers discharge
                  </li>
                  <li>
                    Optimize trucks routes based on accurately collected data
                  </li>
                </ul>
              </div>
            </CarouselElement>
            <CarouselElement>
              <div>
                <Img fluid={images.workflow.childImageSharp.fluid} />
              </div>
              <div>
                <h3>Workflow Management</h3>
                <ul>
                  <li>
                    Streamlined Processes: Simplification and automation of
                    workflows for increased efficiency
                  </li>
                  <li>
                    Task Management: Assign, track, and prioritize tasks for
                    seamless collaboration
                  </li>
                  <li>
                    Instant visibility into the status and progress of workflows
                  </li>
                </ul>
              </div>
            </CarouselElement>
            <CarouselElement>
              <div>
                <Img fluid={images.pedestrian.childImageSharp.fluid} />
              </div>
              <div>
                <h3>Pedestrian Safety</h3>
                <ul>
                  <li>
                    Intelligent traffic lights that enhance safety by
                    controlling the flow of vehicles in the facility.
                  </li>
                  <li>
                    Workers and tugger trains are equipped with tags and readers
                    that trigger visual and sound alerts to prevent potential
                    collisions.
                  </li>
                  <li>
                    Proactive safety measures to prevent accidents, reducing the
                    risk of injuries and enhancing overall safety in industrial
                    facilities.
                  </li>
                </ul>
              </div>
            </CarouselElement>
            <CarouselElement>
              <div>
                <Img fluid={images.tuggertrain.childImageSharp.fluid} />
              </div>
              <div>
                <h3>Tugger Train Tracking</h3>
                <ul>
                  <li>
                    Vehicle utilization with on-board sensors and estimated time
                    of arrival for each station
                  </li>
                  <li>
                    Route Optimization: Optimize routes and reduce travel time
                  </li>
                  <li>
                    Data Analytics: Collect and analyze data for informed
                    decision-making
                  </li>
                </ul>
              </div>
            </CarouselElement>
            <CarouselElement>
              <div>
                <Img fluid={images.assetmanagement.childImageSharp.fluid} />
              </div>
              <div>
                <h3>Asset Management</h3>
                <ul>
                  <li>Real-time visibility of your assets</li>
                  <li>Automate tracking and monitoring processes</li>
                  <li>Improve inventory management and control</li>
                </ul>
              </div>
            </CarouselElement>
          </Carousel>
          <Dots selectedSlide={selectedSlide + 1}>
            <li onClick={() => setSelected(0)} />
            <li onClick={() => setSelected(1)} />
            <li onClick={() => setSelected(2)} />
            <li onClick={() => setSelected(3)} />
            <li onClick={() => setSelected(4)} />
            <li onClick={() => setSelected(5)} />
          </Dots>
        </div>
      </div>
    </ApplicationsContainer>
  );
}

export default Applications;
