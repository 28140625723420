import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Particles, { ParticlesContainer } from './particles';
import Button from '../button';

import layoutStyle from '../../styles/layout';
import { colors, media } from '../../styles/constants';

const IndexHeaderContainer = styled.div`
  margin-top: 3.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledParticlesContainer = styled(ParticlesContainer)`
  .particles canvas {
    opacity: 0.5;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transform: translateZ(1px);

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    text-align: left;
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.lightGrey};
    padding-bottom: 3rem;

    @media (min-width: ${media.tabletPortrait}px) {
      align-items: normal;
      width: 50%;
      padding-bottom: 0;
      margin-right: 2rem;
    }

    h2 {
      font-weight: 500;
    }

    h1,
    h2 {
      padding-bottom: 1rem;

      @media (min-width: ${media.tabletPortrait}px) {
        padding-bottom: 1.75rem;
      }
    }

    ${Button} {
      margin-top: 0.5rem;
    }
  }

  > div:last-child {
    width: 100%;
    height: 100%;

    .slider-wrapper {
      width: 50%;
    }

    .slide {
      background: transparent;
      opacity: 0;
      transition: opacity 300ms ease-in-out;

      &.selected {
        transition: opacity 300ms ease-in-out 300ms;
        opacity: 1;
      }
    }

    @media (min-width: ${media.tabletPortrait}px) {
      width: 45%;
      max-width: 30rem;
      margin: 0 auto;

      .slider-wrapper {
        width: 100%;
      }
    }
  }
`;

function IndexHeader() {
  const data = useStaticQuery(graphql`
    query {
      tag: file(relativePath: { eq: "beacon_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      anchor: file(relativePath: { eq: "ivt-anchor.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      smallTag: file(relativePath: { eq: "small-tag-m.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <IndexHeaderContainer>
      <Particles Container={StyledParticlesContainer}>
        <TitleContainer>
          <div>
            <h1>Powering The Digital Transformation</h1>
            <h2>
              Azitek IoT provides real-time asset visibility to power logistics
              digitalization
            </h2>
            <Button
              secondary
              onClick={() => {
                const element = document.getElementById('content');
                element.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
            >
              Learn More
            </Button>
          </div>
          <Carousel
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            autoPlay
            infiniteLoop
            stopOnHover={false}
            interval={5000}
            transitionTime={500}
          >
            <Img
              fluid={data.tag.childImageSharp.fluid}
              style={{ overflow: 'visible' }}
              imgStyle={{ height: 'auto' }}
            />
            <Img
              fluid={data.anchor.childImageSharp.fluid}
              style={{ overflow: 'visible' }}
              imgStyle={{ height: 'auto' }}
            />
            <Img
              fluid={data.smallTag.childImageSharp.fluid}
              style={{ overflow: 'visible' }}
              imgStyle={{ height: 'auto' }}
            />
          </Carousel>
        </TitleContainer>
      </Particles>
    </IndexHeaderContainer>
  );
}

export default IndexHeader;
