import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import IndexHeader from '../components/headers/index';
import Applications from '../components/applications';
import Logos from '../components/logos';
import ContactForm from '../components/contactForm';
import Button from '../components/button';

import { colors, media } from '../styles/constants';
import layoutStyle from '../styles/layout';
import { contentHeaderStyles } from '../styles/text';
//import AssetManagementIcon from '../assets/svg/LeadingEdge_AssetManagement_Photo.svg';

const TextContainer = styled.div`
  ${contentHeaderStyles};
  flex: 1 1 100%;
  text-align: center;

  @media (min-width: ${media.tabletPortrait}px) {
    flex: 1 0 45%;
    text-align: left;
  }

  p {
    padding-bottom: 1rem;
  }

  ${Button} {
    margin-top: 0.5rem;
  }
`;

const MediaContainer = styled.div`
  flex: 1 1 100%;

  @media (min-width: ${media.tabletPortrait}px) {
    flex: 0 1 50%;
  }

  svg,
  .gatsby-image-wrapper {
    width: 100%;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 5vh;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ProductSection = styled.div`
  > div {
    ${layoutStyle}

    h2 {
      padding-bottom: 1.25rem;
    }

    display: flex;
    flex-direction: column;

    @media (min-width: ${media.tabletPortrait}px) {
      align-items: center;
      flex-direction: row;
    }

    ${MediaContainer} {
      margin-top: 2rem;
    }
  }

  &:first-child {
    background-color: ${colors.background};

    @media (min-width: ${media.tabletPortrait}px) {
      div > ${MediaContainer} {
        margin-left: 3rem;
      }
    }
  }

  &:nth-child(2) {
    background-color: ${colors.white};

    > div {
      flex-direction: column-reverse;

      @media (min-width: ${media.tabletPortrait}px) {
        flex-direction: row;
      }
    }

    @media (min-width: ${media.tabletPortrait}px) {
      div > ${MediaContainer} {
        margin-right: 3rem;
      }
    }
  }
`;

const FormContainer = styled.div`
  ${layoutStyle}
`;

function IndexPage({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      milkrun: file(relativePath: { eq: "milkrun.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      atexample: file(relativePath: { eq: "atexample.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Home" pathname={pathname} />
      <Layout Header={<IndexHeader />}>
        <ProductSection>
          <div>
            <TextContainer>
              <h2>Tugger Train Optimization</h2>
              <p>
                Azitek Tugger Train Tracking System enables companies to monitor
                the performance of tugger trains in industrial manufacturing
                facilities. By leveraging real-time data, the system predicts
                routes, estimates the time of arrival at every station, and
                provides advanced analytics
              </p>
              <Link to="milk-run">
                <Button>See How it Works</Button>
              </Link>
            </TextContainer>
            <MediaContainer>
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/QYgjxDfq55A?&rel=0&cc_load_policy=1&color=white&mute=1&autoplay=1"
                  title="Azitek Tugger Trains"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen
                ></iframe>
              </div>
            </MediaContainer>
          </div>
        </ProductSection>
        <ProductSection>
          <div>
            <MediaContainer>
              <Img fluid={data.atexample.childImageSharp.fluid} />
            </MediaContainer>
            <TextContainer>
              <h2>Leading Edge Asset Management </h2>
              <p>
                Azitek Asset Management IoT system enables companies to gain
                visibility and better manage their assets. By attaching low-cost
                active Tags to every asset, organizations can track the
                inventory status, location, and oversee circular supply chains
                in real-time
              </p>
              <Link to="asset-management">
                <Button>See How it Works</Button>
              </Link>
            </TextContainer>
          </div>
        </ProductSection>
        <Applications />
        <Logos />
        <FormContainer>
          <ContactForm backgroundColor={colors.background} />
        </FormContainer>
      </Layout>
    </>
  );
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default IndexPage;
